<template>
    <div>
        <nav-bar>
            <router-link :to="{ name: 'HomeContact' }" class="btn btn-primary">CONTACT</router-link>
        </nav-bar>
        <section>
            <div class="title">
                <h1>{{ project.title }}</h1>
            </div>
            <div class="content">
                <carousel 
                :items="1" 
                :loop="true" 
                :nav="false"
                :autoplay="true"
                class="part image"
            >
                    <img width="100%" :src="project.img_url">
                    <img width="100%" :src="project.img_url">
                    <img width="100%" :src="project.img_url">
                </carousel>
                <div class="part">
                    <h2>About this project</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt eos commodi, iure dolores modi quas, ullam excepturi laudantium facilis illum tenetur, iste saepe architecto corrupti recusandae voluptas. Dolore asperiores, blanditiis inventore maxime vel impedit ipsa obcaecati, sapiente repudiandae et perspiciatis saepe architecto! Similique, reiciendis nesciunt! Dicta doloremque aut odit quia?
                    </p>
                </div>
                <div class="part">
                    <h2>Technical Sheet</h2>
                    <ul>
                        <li v-for="(techno, index) in project.technos" :key="index">{{ techno }}</li>
                    </ul>
                </div>
                <div class="part">
                    <h2>Resources</h2>
                    <ul>
                        <li>The project is online at <a href="https://rocket.chat" target="_blank">HTTPS://ROCKET.CHAT</a></li>
                        <li>Access the project's source on <a href="https://github.com/IbrahimElOuadifi" target="_blank">GITHUB</a></li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import NavBar from '../../components/main/NavBar.vue'
import Carousel from 'vue-owl-carousel'
export default {
    props: ['project'],
    components: { NavBar, Carousel },
    mounted() {
        if(!this.project) this.$router.push({ name: 'Home' })
    }
}
</script>

<style scoped>
section {
    height: calc(100vh - 120px);
        overflow-y: auto;

}

section .title {
    height: 10%;
}

section .content {
    align-items: flex-start;
    flex-direction: column;
    width: 80vw;
    max-width: 800px;
}

.part {
    padding: 1em .5em;
}

.image {
    width: 100%;
}

ul {
    list-style: circle;
}
</style>